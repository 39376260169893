.Checkbox {
  display: block;
  position: relative;
  padding-left: 4.5rem;
  text-align: left;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.round {
    .Checkbox-main {
      &::before {
        border-radius: 50%;
      }

      &::after {
        background: var(--color-primary) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjkuOEw1LjggOC45IDIuMSA1LjJjLS40LS40LTEuMS0uNC0xLjYgMC0uNC40LS40IDEuMSAwIDEuNkw1IDExLjJjLjQuNCAxLjEuNCAxLjYgMGw4LjktOC45Yy40LS40LjQtMS4xIDAtMS42LS41LS40LTEuMi0uNC0xLjYuMXoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=) no-repeat 50% 50%;
        background-size: 12px;
        border-radius: 50%;
      }
    }
  }

  &.loading {
    .Checkbox-main::before,
    .Checkbox-main::after {
      opacity: 0 !important;
    }

    .Spinner {
      position: absolute;
      left: 0.375rem;
      top: 0.125rem;
      opacity: 0;
      animation: fade-in .2s ease forwards;
      --spinner-size: 1.25rem;
    }
  }

  &.blocking {
    input:not(:checked) ~ .Checkbox-main {
      &::before {
        border-color: var(--color-error);
      }

      &::after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHBvbHlnb24gcG9pbnRzPSIwIDAgMjQgMCAyNCAyNCAwIDI0Ii8+CiAgICA8cGF0aCBmaWxsPSIjZTUzOTM1IiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xOCwzIEMxOS42NTY4NTQyLDMgMjEsNC4zNDMxNDU3NSAyMSw2IEwyMSwxOCBDMjEsMTkuNjU2ODU0MiAxOS42NTY4NTQyLDIxIDE4LDIxIEw2LDIxIEM0LjM0MzE0NTc1LDIxIDMsMTkuNjU2ODU0MiAzLDE4IEwzLDYgQzMsNC4zNDMxNDU3NSA0LjM0MzE0NTc1LDMgNiwzIEwxOCwzIFogTTE2LDExIEw4LDExIEM3LjQ0NzcxNTI1LDExIDcsMTEuNDQ3NzE1MyA3LDEyIEM3LDEyLjU1MjI4NDcgNy40NDc3MTUyNSwxMyA4LDEzIEwxNiwxMyBDMTYuNTUyMjg0NywxMyAxNywxMi41NTIyODQ3IDE3LDEyIEMxNywxMS40NDc3MTUzIDE2LjU1MjI4NDcsMTEgMTYsMTEgWiIvPgogIDwvZz4KPC9zdmc+Cg==);
        opacity: 1;

        // Compensate for svg having incorrect size
        background-size: 1.375rem;
        background-position: -0.125rem -0.125rem;
      }
    }
  }

  input {
    position: absolute;
    z-index: var(--z-below);
    opacity: 0;
  }

  .Checkbox-main {
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      left: 1.1875rem;
      top: 0.1875rem;
      width: 1.125rem;
      height: 1.125rem;
    }

    &::before {
      border: 2px solid var(--color-borders-input);
      border-radius: .25rem;
      background-color: var(--color-background);
      transition: border-color .1s ease, background-color .1s ease;
    }

    &::after {
      background: center no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjkuOEw1LjggOC45IDIuMSA1LjJjLS40LS40LTEuMS0uNC0xLjYgMC0uNC40LS40IDEuMSAwIDEuNkw1IDExLjJjLjQuNCAxLjEuNCAxLjYgMGw4LjktOC45Yy40LS40LjQtMS4xIDAtMS42LS41LS40LTEuMi0uNC0xLjYuMXoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
      background-size: .875rem;
      opacity: 0;
      transition: opacity .1s ease;
    }

    .label {
      display: block;
      text-align: initial;
    }

    .subLabel {
      display: block;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-text-secondary);
    }
  }

  input:checked ~ .Checkbox-main {
    &::before {
      border-color: var(--color-primary);
      background-color: var(--color-primary);
    }

    &::after {
      opacity: 1;
    }
  }

  &[dir=rtl] {
    padding-left: 0;
    padding-right: 4.5rem;

    &.loading {
      .Spinner {
        left: auto;
        right: 0.375rem;
      }
    }

    .label,
    .subLabel {
      text-align: right;
    }

    .Checkbox-main {
      &::before, &::after {
        left: auto;
        right: 1.1875rem;
      }
    }
  }
}
