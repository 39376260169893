#UiLoader {
  height: 100%;
  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100);
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    z-index: var(--z-ui-loader-mask);
    display: flex;


    @media (min-width: 600px) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 100%;
    }

    .left {
      flex: 1;
      background: var(--color-background);
      min-width: 12rem;
      width: 33vw;
      max-width: 26.5rem;

      @media (min-width: 926px) {
        max-width: 40vw;
      }

      @media (min-width: 1276px) {
        width: 25vw;
        max-width: 33vw;
      }

      @media (min-width: 1920px) {
        border-left: 1px solid var(--color-borders);
      }

      @media (max-width: 1275px) {
        flex: 2;
      }

      @media (max-width: 925px) {
        width: 26.5rem !important;
      }

      @media (max-width: 600px) {
        max-width: none;
        width: 100vw !important;
      }
    }

    .middle {
      flex: 3;
      border-left: 1px solid var(--color-borders);
      border-right: 1px solid var(--color-borders);
      position: relative;
      z-index: 1;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: no-repeat center;
        background-size: cover;
        z-index: -1;
        transform-origin: left center;

        .theme-dark body.initial & {
          background-color: #0f0f0f;
        }

        .theme-light body.initial &,
        body:not(.initial) & {
          background-image: url('../../assets/chat-bg.jpg');
        }
      }

      &.with-right-column::before {
        transform: scale(0.67);
      }

      @media screen and (min-width: 1276px) {
        &::before {
          margin: -16rem -5rem -20rem 0;
        }
      }

      &.custom-bg-image::before {
        margin: -1rem;
        background-image: none !important;
      }

      @media (max-width: 1275px) {
        flex: 3;
        border-right: none;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }

    .right {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      height: 100%;
      width: var(--right-column-width);
      min-width: 15.5rem;
      max-width: 26.5rem;
      border-left: 1px solid var(--color-borders);
      background: var(--color-background);
    }
  }

  .blank {
    flex: 1;
    background: var(--color-background);
  }
}
