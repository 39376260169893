.Menu {
  &.fluid .bubble {
    min-width: 13.5rem;
    width: auto;
  }

  .backdrop {
    position: fixed;
    left: -100vw;
    right: -100vw;
    top: -100vh;
    bottom: -100vh;
    z-index: var(--z-menu-backdrop);
  }

  .bubble {
    overflow: hidden;
    display: block;
    list-style: none;
    padding: 0.5rem 0;
    margin: 0;
    position: absolute;
    background-color: var(--color-background);
    box-shadow: 0 .25rem .5rem .125rem var(--color-default-shadow);
    border-radius: var(--border-radius-default);
    min-width: 13.5rem;
    z-index: var(--z-menu-bubble);
    overscroll-behavior: contain;

    transform: scale(0.2);
    transition: opacity .2s cubic-bezier(0.2, 0, 0.2, 1), transform .2s cubic-bezier(0.2, 0, 0.2, 1) !important;

    &.open {
      transform: scale(1);
    }

    &.closing {
      transition: opacity .2s ease-in, transform .2s ease-in !important;
    }

    body.animation-level-0 & {
      transform: none !important;
      transition: opacity .15s !important;
    }

    body.has-open-dialog & {
      transition: none !important;
    }

    --offset-y: calc(100% + 0.5rem);
    --offset-x: 0;

    &.top {
      top: var(--offset-y);
    }

    &.bottom {
      bottom: var(--offset-y);
    }

    &.left {
      left: var(--offset-x);
    }

    &.right {
      right: var(--offset-x);
    }

    &.with-footer {
      padding-bottom: 0;
    }
  }

  .footer {
    padding: 0.5rem 0;
    background: var(--color-chat-hover);
    color: var(--color-text-secondary);
    font-size: 0.8125rem;
    text-align: center;
  }
}
