.auth-form {
  width: 100%;
  max-width: 25.5rem;
  margin: 0 auto;
  padding: 1rem;
  padding-top: 6rem;
  text-align: center;

  @media (min-width: 600px) and (min-height: 450px) {
    padding: 1.5rem;
    padding-top: 6.8rem;

    &.qr {
      padding-top: 5rem;
    }
  }

  #logo, .AvatarEditable label {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 7.5rem;
    height: 7.5rem;
    margin-bottom: 1.75rem;

    @media (min-width: 600px) and (min-height: 450px) {
      width: 10rem;
      height: 10rem;
      margin-bottom: 2.5rem;
    }
  }

  #logo {
    background-size: 100%;
    background: url('../../assets/telegram-logo.svg') center no-repeat;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1;

    @media (min-width: 600px) {
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  .note {
    font-size: 0.875rem;
    line-height: 1.35;
    margin-bottom: 2.5rem;
    color: var(--color-text-secondary);

    @media (min-width: 600px) {
      font-size: 1rem;
      margin-bottom: 3rem;
    }
  }
}

#auth-registration-form,
#auth-phone-number-form,
#auth-code-form,
#auth-password-form,
#auth-qr-form {
  height: 100%;
  overflow-y: auto;
}

#auth-phone-number-form {
  form {
    min-height: 26.25rem;
  }

  .Button {
    margin-top: 2.75rem;
  }

  .Button + .Button {
    margin-top: 1rem;
  }
}

#auth-code-form,
#auth-password-form {
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.auth-number-edit {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: var(--color-text-secondary);
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover, &:focus {
    opacity: 1;
  }
}

#auth-qr-form {
  .qr-container, .qr-loading {
    height: 280px;
  }

  .qr-container {
    opacity: 1;
    transform: scale(1);
    transition: transform 300ms cubic-bezier(0.34, 1.56, 0.64, 1), opacity 300ms;

    &.pre-animate {
      opacity: 0.5;
      transform: scale(0.8);
    }

    canvas {
      padding: .625rem;
      border-radius: var(--border-radius-default);
      background: var(--color-white);
      margin-top: -.625rem;
    }
  }

  h3 {
    margin: 1.5rem 0 1rem 0;
  }

  ol {
    list-style: none;
    counter-reset: item;
    padding: 0 1.75rem;

    li {
      counter-increment: item;
      text-align: left;
      margin: 0.75rem 0;
      display: flex;

      &::before {
        content: counter(item);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.4rem;
        height: 1.4rem;
        padding: 0;
        margin: 0 0.75rem 0 0;
        background: var(--color-primary);
        border-radius: 50%;
        font-size: smaller;
        color: white;
      }
    }
  }
}

#sign-in-password {
  word-break: normal !important;
}
