@import './mixins';

// Common styles for all media-type components across the app.
.media-inner {
  position: relative;

  video,
  img {
    display: block;
  }

  img, video {
    object-fit: cover;
  }

  video {
    background: no-repeat 50% 50%;
    background-size: contain;
  }

  .thumbnail ~ .thumbnail, .thumbnail ~ .full-media, .media-loading {
    position: absolute;
  }
}

.animated-close-icon {
  position: absolute;
  transform: rotate(-45deg);

  &, &::before, &::after {
    width: 1.125rem;
    height: 0.125rem;
    border-radius: 0.125rem;
    background-color: var(--color-text-secondary);
    transition: transform var(--slide-transition);
  }

  &::before, &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
  }

  &::before {
    transform: rotate(90deg);
  }

  &.no-transition {
    &, &::before, &::after {
      transition: none;
    }
  }

  &.state-back {
    transform: rotate(180deg);

    &::before {
      transform: rotate(45deg) scaleX(0.75) translate(0, -0.375rem);
    }

    &::after {
      transform: rotate(-45deg) scaleX(0.75) translate(0, 0.375rem);
    }
  }
}

// Used by ChatList and ContactList components
.chat-list {
  background: var(--color-background);
  height: 100%;
  padding: .5rem .125rem .5rem .4375rem;

  overflow-y: auto;
  body.is-android & {
    @include overflow-y-overlay();
  }

  .scroll-container {
    position: relative;
  }

  .ListItem.chat-item-clickable {
    margin: 0;
  }

  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }

  .no-results {
    color: var(--color-text-meta);
    margin-top: 2rem;
    text-align: center;
  }
}

// Used by Avatar and ProfilePhoto components
div {
  &.color-bg-1 {
    --color-user: var(--color-user-1);
  }

  &.color-bg-2 {
    --color-user: var(--color-user-2);
  }

  &.color-bg-4 {
    --color-user: var(--color-user-4);
  }

  &.color-bg-5 {
    --color-user: var(--color-user-5);
  }

  &.color-bg-6 {
    --color-user: var(--color-user-6);
  }

  &.color-bg-7 {
    --color-user: var(--color-user-7);
  }

  &.color-bg-8 {
    --color-user: var(--color-user-8);
  }

  &.replies-bot-account,
  &.saved-messages {
    --color-user: var(--color-primary);
  }

  &.deleted-account {
    --color-user: var(--color-gray);
  }
}
