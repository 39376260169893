.Loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .Spinner {
    --spinner-size: 2.75rem;
  }
}
