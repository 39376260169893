.Link {
  color: inherit;

  &:hover {
    color: inherit;

    &.GroupCallLink {
      text-decoration: none;
    }
  }
}
